import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Row, Col } from 'react-bootstrap';
import {apiBaseURL,apiURL,apiKey} from './../../constant';
import React, { useEffect, useState, useContext } from "react";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Loader from "./../../Loader";
import userContext from './../../Store';
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


function StudentDash() {
  useEffect(()=>{
    getSectionAssignOfStudent();
  },[])
  const {userData} = useContext(userContext);
  const[showLoader, setLoader]=useState(false)
  const[showSubjectList, setSubjectList]=useState([]);
  const[showChapter, setChapterList]=useState([]);
  const[showSection, setSection]=useState([]);

  const[subjectID,setSubjectID] =useState({subjectID:''})

  // radio button
  const[sltSectionA, setSlSectionA]=useState(true);
  const [checkBtn, setCheckedBtn] = useState("");
  // radio button

  // Section state
  const[language, setlanguage]=useState(false)
  const[subject, setsubject]=useState(false)
  const[chapter, setchapter]=useState(false)

  // Section state

  const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
  const[showPDFLanguage, setPDFLanguage]=useState([]);
  const[showPDFonClickLanguage, setPDFonClickLanguage]=useState({status:false, pdf:null});
  const[showPDFChapter, setPDFChapter]=useState([]);
  const[showPDFonClickChapter, setPDFonClickChapter]=useState({status:false, pdf:null});
  const[showPDFSec_3, setPDFSec_3]=useState([])
  const[showPDFonClickSec_3, setPDFonClickSec_3]=useState({status:false, pdf:null});




  // get chapters list  function


//   get Section Function start
function checkBtnData(secID, isSubject){
	setCheckedBtn(secID)
	if(secID==1){
		setlanguage(true)
		setsubject(false)
		setchapter(false)
		setPDFChapter([])
		setPDFonClickChapter([])
		setPDFonClickSec_3([])
		setPDFSec_3([])

	}else if(secID==2){
		setsubject(true)
		setlanguage(false)
		setPDFonClickLanguage([])
		setPDFLanguage([])
		setPDFonClickSec_3([])
		setPDFSec_3([])

	}else if(secID==3){
		setsubject(false)
		setlanguage(false)
		setchapter(false)
		setPDFonClickLanguage([])
		setPDFLanguage([])
		setPDFChapter([])
		setPDFonClickChapter([])
		
		const xPostData ={
			"classID":12,
			"sectionID":3,
			"isDemoUser":userData.isDemoUser
		}
		fetch(apiBaseURL+apiURL.getQuickNotes,{
			method:"POST",
			headers: {'key' : apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((section_3Data)=>{
			if(section_3Data.status=="success"){
				// cons
				let pdfName= section_3Data.data.quickNotes.pdfName.map((item)=>{
				return {url:section_3Data.data.quickNotes.pdfPath+'/'+item, name:section_3Data.data.quickNotes.chapterName}
			  });
			  setPDFSec_3(pdfName);
			  setPDFonClickSec_3({status:false, pdf:null})
			}else{setErrPopup({
				 status:true,
				 msg:section_3Data.message})
			}
		})
		.catch((catch_err)=>{
			alert(catch_err)
		})
	}else{
		setsubject(false)
		setlanguage(false)
		setchapter(false)
		setPDFonClickLanguage([])
		setPDFLanguage([])
		setPDFChapter([])
		setPDFonClickChapter([])
	}

	if(secID==1 || secID==2 ){
		const xPostData ={
			"section":secID,
			"userRefID" : userData.userRefID,
			"schoolCode": userData.schoolCode	
		}
		fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
			method: "POST",
			headers:{'Key' : apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json())
		.then((subjectData)=>{
			if(subjectData.status=="success"){
				setSubjectList(subjectData.data.subjects)
			}else{
				setSubjectList([])
				alert(subjectData.message)
			}
		})
		.catch((catch_err)=>{
			setSubjectList([])
			alert(catch_err)
		})
	
	}
	
  }


function getSectionAssignOfStudent(){
	setLoader(true)
	const xPostData ={
		"classID":12,
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID
	}
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSection(sectionData.data.assignedSections)
		}else{
			setSection([])
			alert(sectionData.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}
//   get Section Function end

// get Topics function start
 
// get Topics function end
function getChapters(subjectID, isChapter){
	setLoader(true)
	const xPostData ={
		"classID":12,
		"subjectID":subjectID
	}
	fetch(apiBaseURL+apiURL.getChaptersList,{
		method:"POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((chapterData)=>{
		if(chapterData.status=="success"){
			setChapterList(chapterData.data.chapters)
			setSubjectID((prev)=>{
				return {...prev,subjectID:subjectID}
			})
		}else{
			setChapterList([])
			alert(chapterData.message)
		}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})

	if(isChapter=true){
		setchapter(true)
	}else{
		setchapter(false)
	}
}
// get Chapters List function end

function getLanguagePDF(subjectID){
	const xPostData ={
		"classID":12,
		"sectionID":checkBtn,
		"subjectID":subjectID,
		"isDemoUser":userData.isDemoUser
	}
	fetch(apiBaseURL+apiURL.getQuickNotes,{
		method:"POST",
		headers: {'key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((langUagePDFdata)=>{
		if(langUagePDFdata.status=="success"){
			let pdfName= langUagePDFdata.data.quickNotes.pdfName.map((item)=>{
			  return {url:langUagePDFdata.data.quickNotes.pdfPath+'/'+item, name:langUagePDFdata.data.quickNotes.chapterName}
			});
		    setPDFLanguage(pdfName);
			setPDFonClickLanguage({status:false, pdf:null})
		}else{setErrPopup({status:true, msg:langUagePDFdata.message})}
	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
}
function getSubjectPDF(subjectID){
	const xPostData ={
		"classID":12,
		"sectionID":checkBtn,
		"subjectID":subjectID,
		"isDemoUser":userData.isDemoUser
	}
	fetch(apiBaseURL+apiURL.getQuickNotes,{
		method:"POST",
		headers: {'key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((chapter_pdfData)=>{
		if(chapter_pdfData.status=="success"){
			  let pdfName= chapter_pdfData.data.quickNotes.pdfName.map((item)=>{
			  return {url:chapter_pdfData.data.quickNotes.pdfPath+'/'+item, name:chapter_pdfData.data.quickNotes.chapterName}
		});
		    setPDFChapter(pdfName);
			setPDFonClickChapter({status:false, pdf:null})
		}else{setErrPopup({
			status:true,
			msg:chapter_pdfData.message},
			setPDFChapter([])
			)}
			

	})
	.catch((catch_err)=>{
		alert(catch_err)
	})
}
  return (
   <div className={`${StuDashBoardCss.Backbody}`}>
      <Header/>
      <LeftMenu/>
      <div className={`${StuDashBoardCss.backdiv}`}>
			<div className={`${StuDashBoardCss.RowCOlm}`}>
				<div className={`${StuDashBoardCss.headingPractice}`}>Quick Notes</div>
			</div>
      {/* sections code start */}
        {sltSectionA  && 
          <>
            {showSection.map((item)=>{
              return(
                <FormControlLabel className={`${StuDashBoardCss.sectionBtndiv}`} checked={checkBtn==item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onChange={()=>{checkBtnData(item.sectionID,item.isSubject)}}/>
              )
            })}
          </>
        }
      {/* sections code end */}
      <hr/>
      <Container fluid>

       {/* show section start*/}
	   
      
			<form className={`${StuDashBoardCss.TopicWisePractice}`}>
				<Row className='justify-content-start'>

					{language &&
						<Col>
							<label>Language</label>
							<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(event)=>{getLanguagePDF(event.target.value)}}>
								<option className={`d-none`}>Select</option>
								{showSubjectList.map((item)=>{
									return(
										<option value={item.subjectID}>{item.subjectName}</option>
									)
								})}
							</select>
						</Col>
					}
					{subject &&
						<Col>
							<label>Subjects</label>
							<select required className={`${StuDashBoardCss.selectSubject}`}  onChange={(event)=>{getSubjectPDF(event.target.value)}}>
								<option className={`d-none`}>Select</option>
								{showSubjectList.map((item, index)=>{
									return(
										<option key={index} value={item.subjectID}>{item.subjectName}</option>
									)
								})}
							</select>
						</Col>
					}
					{chapter && 
						<Col>
							<label>Chapter</label>
							<select required className={`${StuDashBoardCss.selectSubject}`}>
								<option className={`d-none`}>Select</option>
								{showChapter.map((item)=>{
									return(
										<option value={item.chapterID}>{item.chapterName}</option>
									)
								})}
							</select>
						</Col>
					}
					
				</Row>
			</form>
          
        {/* show section end*/}

		{/* Language PDF  start */}
		<div className={`${StuDashBoardCss.QuickNotesButton}`}>
			{showPDFLanguage.map((item,index)=>{
				return(
					// <button className='btn btn-primary m-3' onClick={(()=>{setPDFonClickLanguage({status:true, pdf:item.url})})}>{item.name}</button>
					<button className='btn btn-primary m-3' onClick={(()=>{setPDFonClickLanguage({status:true, pdf:item.url})})}>
					<div className={`${StuDashBoardCss.pdfIcons}`}><PictureAsPdfIcon className={`${StuDashBoardCss.pdficons}`}/> </div>
						{item.name}
					</button>
				)
			})
			
			}
		</div>

			
				
			
			
			{showPDFonClickLanguage.status &&
				<div className={`${StuDashBoardCss.pdfPop}`}>
					<div onClick={()=>{setPDFonClickLanguage({status:false})}} className={`${StuDashBoardCss.closePDFPop}`}>Close <CancelIcon/></div>
					<div className={`${StuDashBoardCss.pdfShowFrame}`}><iframe src={showPDFonClickLanguage.pdf}/></div>
				</div>
			}

			
		{/* Language PDF  end */}

		{/* Chapters PDF show here start */}
			<div className={`${StuDashBoardCss.QuickNotesButton}`}>
				<Row>
					{showPDFChapter.map((item,index)=>{
						let itemName = item.name.split('|')
						if(userData.isDemoUser==1 && index<1){
							return(
								<Col className={`${StuDashBoardCss.rowButton}`}>
									<button className='btn btn-secondary m-3' onClick={(()=>{setPDFonClickChapter({status:true, pdf:item.url})})}>
									<div className={`${StuDashBoardCss.pdfIcons}`}><PictureAsPdfIcon className={`${StuDashBoardCss.pdficons}`}/> </div>
										{itemName[index]}
									</button>
								</Col>
								
							)
							
						}else if(userData.isDemoUser==0){
							return(
								<Col className={`${StuDashBoardCss.rowButton}`}>
									<button className='btn btn-secondary m-3' onClick={(()=>{setPDFonClickChapter({status:true, pdf:item.url})})}>
									<div className={`${StuDashBoardCss.pdfIcons}`}><PictureAsPdfIcon className={`${StuDashBoardCss.pdficons}`}/></div>
										{itemName[index]}
									</button>
								</Col>
								
							)
						}
						
						
					})}
				</Row>
				
			</div>
			{showPDFonClickChapter.status &&
				<div className={`${StuDashBoardCss.pdfPop}`}>
					<div onClick={()=>{setPDFonClickChapter({status:false})}} className={`${StuDashBoardCss.closePDFPop}`}>Close <CancelIcon/></div>
						<div className={`${StuDashBoardCss.pdfShowFrame}`}><iframe  src={showPDFonClickChapter.pdf}/></div>
				</div>
			}
		{/* Chapters PDF show here end */}

		{/* section-3 pdf show start */}
			<Row>
				<div className={`${StuDashBoardCss.QuickNotesButton}`}>
					{showPDFSec_3.map((item,index)=>{
						let itemName = item.name.split('|')
						return(
							<button className='btn btn-primary m-3' onClick={(()=>{setPDFonClickSec_3({status:true, pdf:item.url})})}>
							<div className={`${StuDashBoardCss.pdfIcons}`}><PictureAsPdfIcon className={`${StuDashBoardCss.pdficons}`}/> </div>
							  {itemName[index]} 
							</button>
						)
					})}
				</div>
			</Row>
			{showPDFonClickSec_3.status &&
				<div className={`${StuDashBoardCss.pdfPop}`}>
					<div onClick={()=>{setPDFonClickSec_3({status:false})}} className={`${StuDashBoardCss.closePDFPop}`}>Close <CancelIcon/></div>
					<div className={`${StuDashBoardCss.pdfShowFrame}`}><iframe src={showPDFonClickSec_3.pdf}/></div>
				</div>
			}
		{/* section-3 pdf show end */}

        </Container>
      </div>

       {/* err popup */}
		{showErrPopup.status && 
		<div className={`${StuDashBoardCss.popupBack}`}>
			<div className={`${StuDashBoardCss.errBox}`}>
				<CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
				<Alert severity="error">
					{showErrPopup.msg}
				</Alert>
			</div>       
		</div>
		}
       {/* err popup */}


	{/* Loader start */}
		{showLoader &&
			<Loader/>
		}
	{/* Loader end */}


    </div>
  
  );
}

export default StudentDash;